// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-markdown-layout-js": () => import("./../../../src/components/MarkdownLayout.js" /* webpackChunkName: "component---src-components-markdown-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-egyetemunk-js": () => import("./../../../src/pages/egyetemunk.js" /* webpackChunkName: "component---src-pages-egyetemunk-js" */),
  "component---src-pages-epuletek-[building]-[room]-js": () => import("./../../../src/pages/epuletek/[building]/[room].js" /* webpackChunkName: "component---src-pages-epuletek-[building]-[room]-js" */),
  "component---src-pages-epuletek-index-js": () => import("./../../../src/pages/epuletek/index.js" /* webpackChunkName: "component---src-pages-epuletek-index-js" */),
  "component---src-pages-erettsegi-js": () => import("./../../../src/pages/erettsegi.js" /* webpackChunkName: "component---src-pages-erettsegi-js" */),
  "component---src-pages-hol-vagyok-js": () => import("./../../../src/pages/hol-vagyok.js" /* webpackChunkName: "component---src-pages-hol-vagyok-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-mi-legyek-js": () => import("./../../../src/pages/mi-legyek.js" /* webpackChunkName: "component---src-pages-mi-legyek-js" */),
  "component---src-pages-orarend-js": () => import("./../../../src/pages/orarend.js" /* webpackChunkName: "component---src-pages-orarend-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-sopron-js": () => import("./../../../src/pages/sopron.js" /* webpackChunkName: "component---src-pages-sopron-js" */),
  "component---src-pages-szakleiras-js": () => import("./../../../src/pages/szakleiras.js" /* webpackChunkName: "component---src-pages-szakleiras-js" */),
  "component---src-pages-tata-js": () => import("./../../../src/pages/tata.js" /* webpackChunkName: "component---src-pages-tata-js" */)
}

