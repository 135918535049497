import React, { createContext, useEffect, useState } from "react"
import jwt from "jsonwebtoken"

import { isBrowser } from "./isBrowser"

export const UserContext = createContext()

export const UserProvider = ({ children, ...rest }) => {
  const [token, setToken] = useState(
    (isBrowser() && window.localStorage.getItem("token")) || ""
  )
  const [neptun, setNeptun] = useState("")
  const [password, setPassword] = useState("")
  const [loggedIn, setLoggedIn] = useState()

  const logout = () => {
    setNeptun("")
    setPassword("")
    setLoggedIn(false)
    setToken("")
    if (isBrowser()) {
      window.localStorage.removeItem("token")
    }
  }

  useEffect(() => {
    const getToken = async token => {
      try {
        const { neptun, password } = await jwt.decode(token)
        setNeptun(neptun)
        setPassword(password)
        setLoggedIn(true)
        if (isBrowser()) {
          window.localStorage.setItem("token", token)
        }
      } catch (error) {
        if (isBrowser()) {
          window.localStorage.removeItem("token")
        }
      }
    }

    if (token) {
      getToken(token)
    }
  }, [token])

  return (
    <UserContext.Provider
      value={{
        neptun,
        password,
        loggedIn,
        setToken,
        logout,
        token,
      }}
      {...rest}
    >
      {children}
    </UserContext.Provider>
  )
}
