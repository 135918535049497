import React, { createContext, useRef } from "react"

export const RoomContext = createContext()

export const RoomProvider = ({ children, ...rest }) => {
  const room = useRef()
  const defaultColor = useRef()

  const setDefaultColor = color => {
    if (!defaultColor.current) {
      defaultColor.current = color
    }
  }

  const setRoom = _room => {
    room.current = _room
  }

  return (
    <RoomContext.Provider
      value={{
        room: () => room.current,
        setRoom,
        defaultColor: () => defaultColor.current,
        setDefaultColor,
      }}
      {...rest}
    >
      {children}
    </RoomContext.Provider>
  )
}
